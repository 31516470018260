import dayjs from 'dayjs';

export const formatDate = (date: DateString | Date, format: string) =>
	dayjs(date).format(format);

export const getItemById = <T extends string, D extends { id: T }>(
	maybeId: T | null,
	maybeItems: Array<D> | null,
) => {
	if (maybeId === null || maybeItems === null) {
		return null;
	}
	const maybeData = maybeItems.find(u => u.id === maybeId);
	return maybeData ?? null;
};
